import React from "react"
import styled from "@emotion/styled"

import Layout from "../Components/layout"
import { theme } from "../Styles/theme"
import { Global, css } from "@emotion/core"
import { ThemeProvider } from "emotion-theming"
import { HeadingPrimary } from "../Styles/headers"
import { GlobalStyles } from "../Styles/global"
import { Section } from "../Styles/section"
import { SectionTagLine } from "../Styles/text"
import TenzinPic from "../images/tenzin-home.jpg"
import Img from "gatsby-image"


const ArticleTimeStamp = styled.p`
  font-family: Oswald, sans-serif;
  font-size: 0.9rem;
  text-transform: uppercase;
  color: ${theme.colors.grey300};
  letter-spacing: 1.1px;
  margin: 1rem 0;
`

const ArticleHeader = styled.div`
  padding: 4rem 1rem;
  margin: 0 auto;
  text-align: center;
  width: 97%;
  max-width: 98%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ArticleBody = styled.div`
  max-width: 650px;
  margin: 0 auto;
  padding: 1rem;
  font-size: 1rem;
  font-family: 'libre baskerville', sans-serif;
  color: ${theme.colors.grey900}
`

const ArticleAuthor = css`
  margin: ${theme.pm.pm100} 0 ${theme.pm.pm400} 0;
  color: ${theme.colors.grey200};
`

const ArticleThumbWrap = styled.div`

`;

const AuthorBio = styled.div`
  display: flex;
  max-width: 650px;
  margin: 3rem auto;
  align-items: center;
  padding: 1rem;

  @media only screen and (max-width: ${theme.breakpoints.mobile}) {
    flex-direction: column;
  }
`

const AuthorProfile = styled.div`
  max-width: 150px;
  margin-right: 2rem;
  flex: 1;
  img {
    width: 100%;
    border-radius: 50%;
  }
`

const AuthorDescription = styled.div`
  font-size: 1rem;
  line-height: 1.5;
  flex: 3;
  font-family: "libre baskerville", sans-serif;
  span {
    font-weight: bold;
  }

  @media only screen and (max-width: ${theme.breakpoints.mobile}) {
    margin-top: 1rem;
  }
`

const Divider = styled.div`
  width: 100px;
  height: 4px;
  background: ${theme.colors.grey900};
  margin: 3rem auto 0;
`

const ArticleThumb = styled.div`

`

function ArticleLayout({ children, pageContext }) {
  const { banner, date, title, author } = pageContext.frontmatter
  String.prototype.splice = function(idx, rem, str) {
    return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem))
  }
  const newDate = new Date(date).toDateString().slice(4);
  const newDateWithComma = newDate.splice(6, 0, ",");
  return (
    <ThemeProvider theme={theme}>
      <Global styles={GlobalStyles} />
      <Layout>
        <Section backgroundColor="secondary" css={{ margin: "75px auto 0" }}>
          <ArticleHeader>
            <ArticleTimeStamp>{newDateWithComma}</ArticleTimeStamp>
            <HeadingPrimary>{title}</HeadingPrimary>
            <ArticleThumbWrap>
              <ArticleThumb>
                {/* <Img sizes={banner}></Img> */}
                {/* <img src={banner} alt=""/> */}
              </ArticleThumb>
            </ArticleThumbWrap>
          </ArticleHeader>
        </Section>
        <Section>
          <ArticleBody>
            <div>{children}</div>
          </ArticleBody>
          <Divider></Divider>
          <AuthorBio>
            <AuthorProfile>
              <img src={TenzinPic} alt="" />
            </AuthorProfile>
            <AuthorDescription>
              <span>I'm {author}</span>. I'm a college counselor. I specialize
              in helping elite high school students get into their dream
              college.
            </AuthorDescription>
          </AuthorBio>
        </Section>
      </Layout>
    </ThemeProvider>
  )
}

export default ArticleLayout


// export const query = graphql`
//   query BlogPostBySlug($slug: String!) {
//     allMdx(fields: { slug: { eq: $slug } }) {
//       html
//       frontmatter {
//         title
//         date(formatString: "MMMM DD, YYYY")
//         author
//         banner {
//           childImageSharp {
//             fluid {
//               src
//             }
//           }
//         }
//       }
//     }
//   }
// `
